<script>
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css';

export default {
  props: {
    options: Object,
  },
  mounted() {
    if (import.meta.client) {
      Fancybox.bind(this.$refs.container, '[data-fancybox]', {
        ...(this.options || {}),
      });
    }
  },
  updated() {
    if (import.meta.client) {
      Fancybox.unbind(this.$refs.container);
      Fancybox.close();

      Fancybox.bind(this.$refs.container, '[data-fancybox]', {
        ...(this.options || {}),
      });
    }
  },
  unmounted() {
    if (import.meta.client) {
      Fancybox.destroy();
    }
  },
};
</script>

<template>
  <div ref="container">
    <slot></slot>
  </div>
</template>

<style></style>